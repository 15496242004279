<template>
  <footer>
    <div class="container">
      <!-- ROW -->
      <div class="row alignc">
        <!-- FOOTER COLUMN 1 -->
        <div class="col-md-4">
          <div class="footer-content">
            <h5>ADDRESS:</h5>
            <p>
              {{
                branchDetails.restaurantsByRestaurantId &&
                branchDetails.restaurantsByRestaurantId.name
              }}
              <br />
              {{
                branchDetails.areasByAreaId &&
                branchDetails.areasByAreaId.name +
                  ", " +
                  branchDetails.areasByAreaId.citiesByCityId.city +
                  "."
              }}
            </p>
          </div>
        </div>

        <!-- FOOTER COLUMN 2 -->
        <div class="col-md-4">
          <div class="footer-content">
            <h5>RESERVATION:</h5>
            <p>
              {{ branchDetails.phone1 }} <br />
              {{
                branchDetails.restaurantsByRestaurantId &&
                branchDetails.restaurantsByRestaurantId.billingEmailAddress
              }}
            </p>
          </div>
        </div>

        <!-- FOOTER COLUMN 3 -->
        <div class="col-md-4">
          <div class="footer-content">
            <h5>OPEN HOURS:</h5>

            <p
              v-for="(timings, index) in restaurantTimings"
              :key="index"
              class="m-0"
            >
              <!-- {{ timings }} -->
              {{ timings.day }} : {{ timings.starting }} - {{ timings.closing }}
              <!-- Monday - Friday:
              {{ restaurantTimings && restaurantTimings[0].starting }} -
              {{ restaurantTimings && restaurantTimings[0].closing }}
              <br />
              Saturday - Sunday:
              {{ restaurantTimings && restaurantTimings[0].starting }} -
              {{ restaurantTimings && restaurantTimings[0].closing }} -->
            </p>
          </div>
        </div>
      </div>
      <!-- /ROW -->

      <!-- FOOTER SOCIAL -->
      <ul class="footer-social">
        <li v-if="socialMediaLinks.facebook != null && socialMediaLinks.facebook != ''">
          <a class="social-facebook" :href="socialMediaLinks.facebook" target="_blank"
            ><i class="fab fa-facebook-f"></i
          ></a>
        </li>
        <li  v-if="socialMediaLinks.twitter != null && socialMediaLinks.twitter != ''">
          <a class="social-twitter" :href="socialMediaLinks.twitter" target="_blank"
            ><i class="fab fa-twitter"></i
          ></a>
        </li>
        <li v-if="socialMediaLinks.youtube != null && socialMediaLinks.youtube != ''">
          <a class="social-youtube" :href="socialMediaLinks.youtube" target="_blank"
            ><i class="fab fa-youtube"></i
          ></a>
        </li>
        <!-- <li>
          <a class="social-pinterest" href="#" target="_blank"
            ><i class="fab fa-pinterest"></i
          ></a>
        </li> -->
        <li v-if="socialMediaLinks.instagram != null && socialMediaLinks.instagram != ''">
          <a class="social-instagram" :href="socialMediaLinks.instagram" target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
        </li>
      </ul>
      <!-- /FOOTER SOCIAL -->

      <!-- FOOTER COPYRIGHT -->
      <!-- <div class="copyright">
        Copyright &copy; 2021, Dina . Designed by MatchThemes.com
      </div> -->
      <!-- /FOOTER COPYRIGHT -->

      <!-- FOOTER SCROLL UP -->
      <div class="scrollup">
        <a class="scrolltop" href="#">
          <i class="fas fa-chevron-up"></i>
        </a>
      </div>
      <!-- /FOOTER SCROLL UP -->
    </div>
    <!--container-->
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    branchDetails() {
      return this.$store.getters["branchDetails/branchDetails"];
    },
    restaurantTimings() {
      if (this.branchDetails.timings) {
        const timings = JSON.parse(this.branchDetails.timings);
        timings.forEach((timing) => {
            timing.starting = this.getTimingsIn12HourFormat(timing.starting);
            timing.closing = this.getTimingsIn12HourFormat(timing.closing);
        });
        return timings;
      }
    },
    socialMediaLinks () {
      return this.$store.getters["branchDetails/socialMediaLinks"];
    }
  },
  methods: {
     getTimingsIn12HourFormat: function (timings) {
        let date = new Date();
        date.setHours(parseInt(timings.split(":")[0]), parseInt(timings.split(":")[1]));
        return date.toLocaleString('UTC', {
            hour: 'numeric',
            hour12: 'true',
            minute: 'numeric',
        });
     }
  }
};
</script>

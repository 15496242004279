<template>
  <!-- HEADER -->
    <header id="header-1">
      <div class="headerWrap-1">
        <nav class="navbar-1">
          <!-- TOP LEFT PAGE TEXT  -->
          <!-- <div class="top-location">
            <span class="info-txt">
              {{
                branchDetails.areasByAreaId && branchDetails.areasByAreaId.name
              }},
            </span>
            <span class="info-txt">{{
              branchDetails.areasByAreaId &&
              branchDetails.areasByAreaId.citiesByCityId.city
            }}</span>
          </div> -->
          <!-- TOP RIGHT PAGE TEXT  -->
          <!-- <div class="book-now">
            <span class="info-txt">Book Now</span>
            <span class="info-txt">{{ branchDetails.phone1 }}</span>
          </div> -->

          <div class="nav-content-1">
            <!-- LOGO -->
            <div class="logo-1">
              <router-link :to="`/${$route.params.id}`"
                ><img
                  class="img-fluid bg-white logo-img"
                  :src="
                    branchDetails.restaurantsByRestaurantId &&
                    branchDetails.restaurantsByRestaurantId.smallPicture
                  "
                  alt=""
              /></router-link>
            </div>
          </div>
        </nav>
      </div>
      <!--headerWrap-->
    </header>
    <!-- /HEADER -->
</template>

<script>
  export default {
    name: "Navbar",
    computed: {
      branchDetails() {
        return this.$store.getters["branchDetails/branchDetails"];
      },
    }
  };
</script>
<style scoped>
.nav-content-1{
  width: 100%;
}
.logo-img{
  max-height: 150px;
  border-radius: 100px;
}
</style>

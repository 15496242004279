<template xmlns="http://www.w3.org/1999/html">
    <section id="wrap-content" class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="reservation_txt">
                        <section id="wrap-content" class="page-content" v-if="submitted">
                            <div class="col-sm-12 widget widget_recent_entries text-center">
                                <h4 class="reservation-text">Your feedback has been submitted, Thank You!</h4>
                                <img src="/assets/images/tick.svg" alt="Tick Icon" class="tick-icon mt-4">
                            </div>
                        </section>
                        <section id="wrap-content" class="page-content" v-else-if="this.alreadySubmitted('alreadySubmitted')">
                            <div class="col-sm-12 widget widget_recent_entries text-center">
                                <h4 class="reservation-text">We have already received your feedback.</h4>
                                <h4> Thank you so much for your time!</h4>
                                <img src="/assets/images/tick.svg" alt="Tick Icon" class="tick-icon mt-4">
                            </div>
                        </section>
                        <section id="wrap-content" class="page-content" v-else-if="isEmptyFeedbackQuestions">
                            <div class="col-sm-12 widget widget_recent_entries text-center">
                                <h4 class="reservation-text">No feedback questions exists. Kindly initialize feedback form.</h4>
                            </div>
                        </section>

                        <form method="post" id="reservation-form" @submit.prevent="executeCaptcha()" v-else>
                            <RestaurantInformation/>
                            <CustomerInformation/>
                            <h1 class="post-title single-post-title">Feedback Form</h1>
                            <FeedBackQuestions/>
                            <br/>
                            <p class="alignc"><input type="submit" value="Submit" id="submit" /></p>
                            <VueRecaptcha
                                ref="recaptcha"
                                @verify="submitFeedback"
                                size="invisible"
                                :sitekey="sitekey"
                                name="recaptcha"
                            ></VueRecaptcha>

                        </form>
                    </div>
                    <!-- reservation_txt -->

                </div>
                <!--col-md-10-->
            </div>
            <!--row-->
        </div>
        <!--container-->
    </section>
</template>

<script>

import CustomerInformation from "@/components/FeedbackComponents/CustomerInformation.vue";
import FeedBackQuestions from "@/components/FeedbackComponents/FeedBackQuestions.vue";
import RestaurantInformation from "@/components/FeedbackComponents/RestaurantInformation.vue";
import { VueRecaptcha } from 'vue-recaptcha';
import {verifyRecaptcha} from "@/api/recaptchaApi";
import Swal from "sweetalert2";

export default {
    name: "BranchFeedback",
    components: {RestaurantInformation, FeedBackQuestions, CustomerInformation, VueRecaptcha},
    data () {
      return {
          submitted: false
      }
    },
    computed: {
        waiters({commit}) {
            return this.$store.getters["feedbackDetails/waiters"];
        },
        tables({commit}){
            return this.$store.getters["feedbackDetails/tables"];
        },
        isEmptyFeedbackQuestions(){
            return this.$store.getters["feedbackDetails/feedbackQuestions"] === null;
        },
        sitekey () {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    methods:{
        async submitFeedback(response) {
            await this.verifyCaptcha(response)
            this.$store.getters["feedbackResponseDetails/getBranchFeedbackResponse"].branchFeedbackAnswers = this.$store.getters["feedbackDetails/feedbackAnswer"];
            console.log(this.$store.getters["feedbackResponseDetails/getBranchFeedbackResponse"])
            for (let feedbackAnswer of this.$store.getters["feedbackResponseDetails/getBranchFeedbackResponse"].branchFeedbackAnswers) {
                if (feedbackAnswer.answer === '') {
                    feedbackAnswer.answer = 'N/A'
                }
                if (feedbackAnswer.questionType === 'checkboxes') {
                    feedbackAnswer.answer = feedbackAnswer.answer.toString();
                }
                if (feedbackAnswer.questionType === 'text') {
                  feedbackAnswer.answer = feedbackAnswer.answer.toString();
                }
            }
            await this.$store.dispatch('feedbackResponseDetails/saveBranchFeedback', this.decryptRestaurantId(this.$route.params.id))
            this.submitted = true
            window.scrollTo(0,0);
        },

        executeCaptcha() {
            this.$refs.recaptcha.execute();
        },

        async verifyCaptcha(response) {
            try {
                const formData = new FormData();
                formData.append('token', response)
                const res = await verifyRecaptcha(formData)
                console.log(res.data)
                if (!res.data.body) {
                    Swal.showLoading();
                    await Swal.fire({
                        title: 'Error while saving feedback',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    });
                }
            } catch (e) {
                Swal.showLoading();
                await Swal.fire({
                    title: 'Error while saving feedback',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
            }
        }
    }
}
</script>

<style scoped>
.post-title{
    color: black;
    text-align: center;
    padding-bottom: 30px;
}

.tick-icon {
    display: block;
    margin: 0 auto;
    width: 10%;
}

</style>
<template>
  <!-- MAIN WRAP CONTENT -->
  <section id="wrap-content" class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <MenuNavigation />
          <!-- GALLERY -->
          <div class="gallery-3colgrid-content">
            <div
              class="
                menu-holder menu-3col-grid-image
                gallery-holder
                clearfix
                gallery
                margin-left-4
              "
            >
              <template v-for="(itemWithImages, index) in itemsWithImages">
                <GalleryItem
                  :key="index"
                  :item="itemWithImages"
                  :itemIndex="index"
                />
              </template>
            </div>
          </div>
          <!-- /GALLERY -->
        </div>
        <!--col-md-12-->
      </div>
      <!--row-->
    </div>
    <!--container-->
  </section>
  <!-- /MAIN WRAP CONTENT -->
</template>
<script>
import Showcase from "@/components/GlobalComponents/Showcase.vue";
import GalleryItem from "@/components/GalleryComponents/GalleryItem.vue";
export default {
  name: "GalleryView",
  components: { Showcase, GalleryItem },
  computed: {
    itemsWithImages() {
      let parsedItems = [];
      const data = this.$store.getters["menuDetails/menuDetails"];
      data.map((items) => {
        items.menuItemsById.map((item) => {
          if (item.menuItemImagesByMenuItemId.length > 0) {
            parsedItems.push({
              itemName: item.name,
              images: item.menuItemImagesByMenuItemId,
            });
          }
        });
      });
      return parsedItems;
    },
  },
  // mounted() {
  //   console.log("running gallery");
  //   this.loadScript();
  // },
};
</script>
<style scoped>
@media screen and (max-width: 580px) {
  .margin-left-4 {
    margin-left: 4%;
  }
}
</style>

import { getBranchMenu } from '@/api/branchesApi.js'

const menuDetails = {
    namespaced: true,
    state: {
        menuDetails: [],
    },
    getters: {
        menuDetails(state) {
            return state.menuDetails
        },

    },
    mutations: {
        setMenuDetails(state, menuDetails) {
            state.menuDetails = menuDetails
        },

    },
    actions: {
        /*API CALL TO GET ALL MENU ITEMS BY RESTAURANT ID */
        async getMenuDetails({ commit }, payload) {
            try {
                let finalCategories;
                const { data } = await getBranchMenu(payload);
                
                
                finalCategories = data.filter((category) => {
                    return (category.menuItemsById.length > 0);
                });
                // finalCategories = finalCategories.sort(
                //     (category1, category2) => category2.priority - category1.priority
                // );
                // finalCategories;
                console.log(finalCategories);
                commit('setMenuDetails', finalCategories);
            } catch (error) {
                console.log(error)
            }
        }
        ,

    }
}

export default menuDetails;
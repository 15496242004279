<template>
     <!-- MENU -->
        <div class="nav-holder nav-holder-1">
          <ul id="menu-menu-1" class="menu-nav menu-nav-1">
            <li class="menu-item" :class="{'active-menu-item' : navIsMenu}">
              <router-link :to="`/${$route.params.id}`">Menu</router-link>
            </li>
            <li class="menu-item" :class="{'active-menu-item' : navIsGallery}">
              <router-link :to="`/gallery/${$route.params.id}`"
                >Gallery</router-link
              >
            </li><li class="menu-item" :class="{'active-menu-item' : navIsFeedback}">
              <router-link :to="`/feedback/${$route.params.id}`"
                >Feedback</router-link
              >
            </li>
          </ul>
        </div>
        <!-- /MENU -->
</template>
<script>

export default ({
    name: "MenuNavigation",
    computed: {
        navIsMenu () {
            return this.$route.name === 'menu';
        },
        navIsGallery () {
            return this.$route.name === 'gallery';
        },
        navIsFeedback () {
            return this.$route.name === 'feedback';
        }
} 
})
</script>
<style scoped>
    .menu-item > a {
    font-size: 16px;
    color: black !important;
    }
    .active-menu-item  a{
    color: #BC8D4B !important;
    }
    .menu-item > a:hover {
    color: black !important;
    }
    .menu-nav{
      margin-bottom: 10px;
    }
</style>
import { render, staticRenderFns } from "./Questions.vue?vue&type=template&id=936d182a&scoped=true"
import script from "./Questions.vue?vue&type=script&lang=js"
export * from "./Questions.vue?vue&type=script&lang=js"
import style0 from "./Questions.vue?vue&type=style&index=0&id=936d182a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936d182a",
  null
  
)

export default component.exports
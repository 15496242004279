import {saveBranchFeedback} from "@/api/feedbackApi";
import Swal from "sweetalert2";

function defaultState() {
    return {
        "customerName": '',
        "customerPhone": '',
        "customerEmail": '',
        "customerAddress": null,
        "customerDOB": null,
        "hearAboutUs": 'Social Media',
        "md5Hash": null,
        "averageScore": null,
        "dateAndTimeOfVisit": new Date(),
        "waiters": {
            "id": 0
        },
        "tables": {
            "id": 0
        },
        "branchFeedbackAnswers": [],
        "orderId": null
    }
}

const feedbackResponseDetails = {
    namespaced: true,
    state: {
        branchFeedbackResponse: defaultState()
    },
    getters: {
        getBranchFeedbackResponse(state) {
            return state.branchFeedbackResponse;
        }
    },
    mutations: {
        setWaiters(state, waiters) {
            state.branchFeedbackResponse.waiters.id = waiters
        },
        setTables(state, tables) {
            state.branchFeedbackResponse.tables.id = tables
        },
        setToInitialState(state){
            state.branchFeedbackResponse = defaultState()
        }
    },
    actions:{
        updateTables({commit}, tables) {
            commit('setTables', tables);
            console.log("tables: ", tables)
        },
        updateWaiters({commit}, waiter) {
            commit('setWaiters', waiter);
        },
        async saveBranchFeedback({commit, state}, branchId) {
            let saveFeedback = await saveBranchFeedback(branchId, state.branchFeedbackResponse)
                .catch(ex => {
                    Swal.showLoading();
                    Swal.fire({
                        title: 'Error while saving feedback',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    });
                })
            if (saveFeedback.status && saveFeedback.status === 200){
                let d = new Date();
                d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
                let expires = "expires=" + d.toGMTString();
                document.cookie =
                    "alreadySubmitted=true;" + expires + ";path=/";
            }
        }
    }
}

export default feedbackResponseDetails;
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Axios } from './axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/mixins/globalMethods'
import Loader from "@/components/GlobalComponents/Loader.vue"
import MenuNavigation from "@/components/GlobalComponents/menuNavigation.vue"
import VueResizeText from 'vue-resize-text';
import ImgixClient from '@imgix/js-core';

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import Swal from "sweetalert2";

const client = new ImgixClient({
  domain: 'bayked.imgix.net'
});

// Vue.use(SpinnerPlugin)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(VueResizeText);


Vue.component('Loader', Loader);
Vue.component('MenuNavigation', MenuNavigation);


Vue.config.productionTip = false

Vue.prototype.$http = Axios;
Vue.prototype.$imgix = client;
Axios.get('/app-version/live?platform=web&app_name=menus')
    .then(async response => {
        if (response.data.status !== 200) {
            console.error('Error fetching version:', response.data.message);
            new Vue({
                router,
                store,
                render: h => h(App)
            }).$mount('#app')
        }
        const latestVersion = response.data.body.appVersion;
        const currentVersion = localStorage.getItem("VUE_APP_VERSION") || ''; // Assuming you have set the app version as an environment variable

        // Compare versions
        if (latestVersion !== currentVersion) {

            // Update version in local storage and reload page
            localStorage.setItem("VUE_APP_VERSION", latestVersion);
            location.reload(true);

        } else {
            // Versions match, proceed with app initialization
            new Vue({
                router,
                store,
                render: h => h(App)
            }).$mount('#app');
        }
    })
    .catch(error => {
      // Proceed with app initialization even if version check fails
      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    });


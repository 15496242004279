import Vue from 'vue'
import CryptoJS from "crypto-js";

Vue.mixin({
    data() {
        return {
          sauce: 'OkKYetiB4@dy' 
        } 
    },
    methods: {
        encryptTestId(number) {
            console.log(number);
            const data = CryptoJS.AES.encrypt(number + '', this.sauce).toString().replaceAll('+', 'xMl3Jk').replaceAll('/', 'Por21Ld').replaceAll('=', 'Ml32');
            console.log(data);
        },
        /* DECRYPT THE ID */
        decryptRestaurantId(dataString) {
            //decrypt
            const includeEncryptedCharacters = dataString.toString().replaceAll('xMl3Jk', '+' ).replaceAll('Por21Ld', '/').replaceAll('Ml32', '=');
            return CryptoJS.AES.decrypt(includeEncryptedCharacters, this.sauce).toString(CryptoJS.enc.Utf8);
        },
        loadScript() {
            this.headId = document.getElementsByTagName("head")[0];
            this.newScript = document.createElement("script");
            this.newScript.type = "text/javascript";
            this.newScript.src = "/assets/js/init.js";
            setTimeout(() => {
                this.headId.appendChild(this.newScript);
            }, 500);
        },
        getOptimizedURL(originalURL, type = 'gallery', height = false, width = false) {
            const imageURLObject = new URL(originalURL);
            const finalURLForImgix = '/menu/' + imageURLObject.pathname.split('%2F')[1] + '/' + imageURLObject.pathname.split('%2F')[2] + '/' + imageURLObject.pathname.split('%2F')[3].replaceAll('+', ' ');
            if (type === 'display') {
                if (!width) {
                    return this.$imgix.buildURL(finalURLForImgix, {h: height, fit: 'crop'})
                } else {
                    return this.$imgix.buildURL(finalURLForImgix, {h: height, w: width, fit: 'crop'})
                }
            }
            return this.$imgix.buildURL(finalURLForImgix, {q: '10'});
      
        },
        alreadySubmitted (cookieName) {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(cookieName + '=')) {
                    const cookieValue = cookie.substring(cookieName.length + 1);
                    return cookieValue === 'true';
                }
            }
            return false;
        },
    }
})

<template>
    <section class="menu-3col-content">
      <div class="container">
          <template v-for="(feedbackQuestion, index) in feedbackQuestions">
              <!-- STARTERS -->
              <div class="categ-name">
                  <h2>{{index+1}}. {{feedbackQuestion.question}}</h2>
              </div>
              <div :id="'cat_' + index">
                  <div class="widget widget_recent_entries " >
                      <Questions :questions="feedbackQuestion" :question-no="index"/>
                      <hr v-if="index<feedbackQuestions.length-1" class="dotted">
                  </div>
              </div>
          </template>
      </div>

  </section>
</template>

<script>

import Questions from "@/components/FeedbackComponents/Questions.vue";

export default {
    name: "FeedBackQuestions",
    components: {Questions},
    computed: {
        feedbackQuestions() {
            return this.$store.getters["feedbackDetails/feedbackQuestions"]
        }
    }
}
</script>

<style scoped>
.categ-name{
    text-align: left !important;
    margin-bottom: 20px !important;
}
@media screen and (min-width: 700px) {
    .categ-name h2 {
        font-size: 20px !important;
    }
}
@media screen and (max-width: 700px) {
    .categ-name h2{
        font-size: 15px !important;
    }
}
</style>
import {getBranchTables, getBranchWaiters, getFeedbackQuestions} from "@/api/feedbackApi";

const feedbackDetails = {
    namespaced: true,
    state: {
        feedbackQuestions: [],
        feedbackAnswer: [],
        waiters: [],
        tables: []
    },
    getters: {
        feedbackQuestions(state) {
            return state.feedbackQuestions
        },
        feedbackAnswer(state){
            return state.feedbackAnswer
        },
        waiters(state) {
            return state.waiters
        },
        tables(state) {
            return state.tables
        }
    },
    mutations: {
        setFeedbackQuestions(state, feedbackQuestions) {
            state.feedbackQuestions = feedbackQuestions;
        },
        setFeedbackAnswer(state, feedbackAnswer){
            state.feedbackAnswer = feedbackAnswer;
        },
        setWaiters(state, waiters){
            state.waiters = waiters;
        },
        setTables(state, tables){
            state.tables = tables;
        }
    },
    actions: {
        async getBranchFeedbackQuestions({commit}, branchId) {
            let branchFeedbackQuestions = await getFeedbackQuestions(branchId).catch(ex => {
                console.log("Error while fetching branch feedback questions!");
            })
            if(branchFeedbackQuestions.data.status === 200){
                console.log("Successfully fetch branch feedback questions!");
            }else{
                console.log("Error while fetching branch feedback questions!");
            }
            commit('setFeedbackQuestions', branchFeedbackQuestions.data.body)
            let branchFeedbackAnswers = []
            if(branchFeedbackQuestions.data.body !== null) {
                for (let question of branchFeedbackQuestions.data.body) {
                    let answer = {question: question.question, questionType: question.type, answer: ''}
                    branchFeedbackAnswers.push(answer);
                }
            }

            commit('setFeedbackAnswer', branchFeedbackAnswers)
        },
        async getBranchWaiters({commit}, branchId){
            let params = new URLSearchParams();
            params.append("branch_id", branchId);
            let branchWaiters = await getBranchWaiters(params).catch( ex => {
                console.log("Error while fetching branch waiters!");
            })
            if(branchWaiters.status === 200){
                commit('setWaiters', branchWaiters.data)
            }
        },
        async getBranchTables({commit}, branchId){
            let params = new URLSearchParams();
            params.append("branch_id", branchId);
            let branchTables = await getBranchTables(params).catch( ex => {
                console.log("Error while fetching branch tables!");
            })
            if(branchTables.status === 200) {
                commit('setTables', branchTables.data)
            }
        }
    }
}

export default feedbackDetails;
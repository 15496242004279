import Vue from 'vue'
import Vuex from 'vuex'
import branchDetails from './modules/branchDetails.js';
import menuDetails from "./modules/menuDetails.js";
import feedbackDetails from "./modules/feedbackDetails.js";
import feedbackResponseDetails from "./modules/feedbackResponseDetails.js";
import BranchQRStatsDetails from "@/helperComponents/BranchQRStatsDetails";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    show: false,
    itemImages: [],
    branchDetails: {}
  },
  getters: {
    itemImages(state) {
      return state.itemImages
    },
    show(state) {
      return state.show
    }

  },
  mutations: {
    setImages(state, images) {

      state.itemImages = images
    },
    setShow(state, isShow) {
      state.show = isShow
    },

  },
  actions: {
    setImages(context, images) {
      context.commit('setImages', images)
    },
    setShow(context, isShow) {
      context.commit('setShow', isShow)
    },


  },
  modules: {
    branchDetails,
    menuDetails,
    feedbackDetails,
    feedbackResponseDetails,
    BranchQRStatsDetails
  }
})

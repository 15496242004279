<template>
  <div class="menu-post gallery-post">
    <a :href="'#itemWithImage_' + itemIndex">
      <div class="item-content-bkg gallery-bkg">
        <div
          class="gallery-img"
          :style="`background-image: url(${getOptimizedURL(item.images[0].imageUrl, 'display', 400)})`"
        ></div>
        <div class="menu-post-desc">
          <h4 class="mt-6">{{ item.itemName }}</h4>
          <div class="gallery-mglass">
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    </a>
    <div :id="'itemWithImage_' + itemIndex" class='hidden_gallery'>
      <a v-for="(galleryImage) in item.images" :key="Date.now() + Math.floor(Math.random() * 100)" :title='item.itemName' :href="getOptimizedURL(galleryImage.imageUrl, 'gallery')"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryItem",
  props: ["item", "itemIndex"]
};
</script>
<style scoped>
.hidden_gallery{
  overflow: hidden;
  display: none;
  visibility: hidden;
}
.mt-6 {
  margin-top: 5rem;
}
</style>
<template>
  <section id="wrap-content" class="page-content">
      <div class="row" v-if="questions.type==='emoticon'" style="justify-content: space-around">
          <div class="block"  v-for="(emoticon, emoticonIndex) in allEmoticons" :key="emoticonIndex">
              <div style="display: inline-block;" class="col-md-4 col-sm-1 col-xs-1">
                  <img class="makeGray feedbackIcon" :class="questionNo + '_' + emoticonIndex + '_q_index'" @click="emoticonClick($event, questionNo, emoticonIndex, emoticon, questions)" :src="getEmoticonURL(emoticon)"
                       :value="emoticon" :id="questionNo + '_'+ emoticonIndex + '_'+ 'emoticons'"  alt="">
              </div>
          </div>
      </div>
      <div class="row" v-if="questions.type === 'radio'">
          <div v-for="(radioOptions, radioIndex) in questions.options" :key="radioIndex" :class="'col-sm-'+ 12/questions.options.length">
              <input type="radio" :id="`radio${questionNo}${radioIndex}`" v-model="$store.getters['feedbackDetails/feedbackAnswer'][questionNo].answer" v-bind:value="radioOptions">
              <label :for="`radio${questionNo}${radioIndex}`" >&nbsp;{{radioOptions}}</label>
          </div>
      </div>
      <div class="row" v-if="questions.type==='checkboxes'">
          <div class="checkbox col-md-6" v-for="(checkboxOptions, checkboxIndex) in questions.options" v-bind:key="'feedback_checkbox_'+checkboxIndex + Math.random()">
              <label>
                  <input type="checkbox" v-on:click="checkboxClicked(questionNo, checkboxOptions, $event)" :value="checkboxOptions"/>&nbsp;{{checkboxOptions}}
              </label>
          </div>
      </div>
      <div class="row" v-if="questions.type==='comment'">
         <input type="text"  v-model="$store.getters['feedbackDetails/feedbackAnswer'][questionNo].answer" placeholder="Type Some Comment Here" class="form-control reservation-fields">
      </div>
    <template>
      <div v-if="questions.type === 'text'">
        <div class="row">
          <p>
        <textarea
            name="message"
            id="message2"
            class="reservation-fields"
            cols="83"
            rows="4"
            tabindex="4"
            v-model="$store.getters['feedbackDetails/feedbackAnswer'][questionNo].answer"
            placeholder="Write your suggestions.."
        ></textarea>
          </p>
        </div>
      </div>
    </template>

  </section>
</template>

<script>

export default {
    name: "Questions",
    props: ["questions", "questionNo"],
    data(){
        return{
            allEmoticons:["Dissatisfied", "Neutral", "Satisfied", "Awesome"],
        }
    },
    methods:{
        getEmoticonURL: function(emoticon){
            const basePath= '/img/emoji/'
            switch(emoticon){
                case 'Dissatisfied':
                    return basePath + 'mad.png'
                case 'Neutral':
                    return basePath + 'confused.png'
                case 'Satisfied':
                    return basePath + 'smiling.png'
                case 'Awesome':
                    return basePath + 'happy.png'
            }
        },
        emoticonClick: function(event, questionIndex, emotionIndex, emoticon, question){
            question.answer= emoticon;
            for (let i = 0; i < this.allEmoticons.length; i++) {
                document.getElementsByClassName(questionIndex + "_" + i + "_q_index")[0].classList.add('makeGray');
            }
            document.getElementById(event.target.id).classList.remove('makeGray')
            this.$store.getters['feedbackDetails/feedbackAnswer'][questionIndex].answer = emoticon
        },
        checkboxClicked: function(questionNo, option, e){
            let question = this.$store.getters['feedbackDetails/feedbackAnswer'][questionNo]
            if(e.target.checked){
                if(question.answer===''){
                    question.answer=[];
                    question.answer.push(option);
                }else{
                    question.answer.push(option);
                }
            }else{
                let index= question.answer.indexOf(option)
                if(index>-1){
                    question.answer.splice(index, 1)
                }
            }
        },
    }
}
</script>



<style scoped>
.makeGray{
    filter: grayscale(100%);
}
.row{
    padding-left: 5px;
    padding-right: 5px;
}
.reservation-fields{
    margin: 10px !important;
}
@media screen and (min-width: 700px) {
    .feedbackIcon {
        max-width: 70px !important;
    }
}
@media screen and (max-width: 700px) {
    .feedbackIcon {
        max-width: 45px !important;
    }
}
</style>
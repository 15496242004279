<template>
  <!-- TOP IMAGE HEADER -->
  <section class="topSingleBkg topPageBkg">
    <div class="item-content-bkg">
      <div
        class="item-img"
        :style="`background-image: url(${
          branchDetails.restaurantsByRestaurantId &&
          branchDetails.restaurantsByRestaurantId.bigPicture
        });`"
      ></div>
      <div class="inner-desc">
        <!-- LOGO -->
        <div class="showcase-logo">
          <router-link :to="`/${$route.params.id}`"
            ><img
              class="img-fluid bg-white"
              :src="
                branchDetails.restaurantsByRestaurantId &&
                branchDetails.restaurantsByRestaurantId.smallPicture
              "
              alt=""
          /></router-link>
        </div>
        <h1 class="post-title single-post-title res-main-name">
          {{
            branchDetails.restaurantsByRestaurantId &&
            branchDetails.restaurantsByRestaurantId.name
          }}
        </h1>
        <span class="post-subtitle">{{
                branchDetails.areasByAreaId && branchDetails.areasByAreaId.name
              }}, {{
              branchDetails.areasByAreaId &&
              branchDetails.areasByAreaId.citiesByCityId.city
            }}</span>
        <!-- MENU -->
        <!-- <div class="nav-holder nav-holder-1 nav-holder-desktop">
          <ul id="menu-menu-1" class="menu-nav menu-nav-1">
            <li class="menu-item">
              <router-link :to="`/${$route.params.id}`">Menu</router-link>
            </li>
            <li class="menu-item">
              <router-link :to="`/gallery/${$route.params.id}`"
                >Gallery</router-link
              >
            </li>
          </ul>
        </div> -->
        <!-- /MENU -->
      </div>
    </div>
  </section>
  <!-- /TOP IMAGE HEADER -->
</template>

<script>
export default {
  name: "Showcase",
  computed: {
    branchDetails() {
      return this.$store.getters["branchDetails/branchDetails"];
    },
  },
};
</script>
<style scoped>

.showcase-logo img{
  max-height: 80px;
  border-radius: 50px;
}
.res-main-name{
  padding-top:2% ;
}
.post-subtitle{
  font-size: 18px;
  opacity: 1 !important;
}
.topSingleBkg{
  margin-bottom: 0 !important;
}
</style>
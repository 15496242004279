import {saveNewQRStatsInformation} from "@/api/firestoreRestaurantApi";
import axios from "axios";
import {getBranchDetails} from "@/api/branchesApi";

const BranchQRStatsDetails = {
    namespaced: true,
    state: {
      qrStatsInformation: {
          "restaurantId": 0,
          "restaurantName": "",
          "branchId": 0,
          "branchName": "",
          "year": new Date().getFullYear(),
          "month": new Date().getMonth() + 1,
          "country": "",
          "city": "",
          "platform": "",
          "uniqueScan": 0
      }
    },
    getters: {
      qrStatsInformation(state) {
          return state.qrStatsInformation;
      }
    },
    mutations: {
        setLocation(state, location) {
            state.qrStatsInformation.city = (location && location.city) ? location.city : 'Unknown'
            state.qrStatsInformation.country = (location && location.country_name) ? location.country_name : 'Unknown'
        },
        setPlatform(state, platform) {
            state.qrStatsInformation.platform = platform
        },
        setBranchDetails(state, branchDetails) {
            state.qrStatsInformation.branchId = branchDetails.id;
            state.qrStatsInformation.branchName = branchDetails.areasByAreaId.name;
            state.qrStatsInformation.restaurantId = branchDetails.restaurantsByRestaurantId.id;
            state.qrStatsInformation.restaurantName = branchDetails.restaurantsByRestaurantId.name;
        },
        setIsUniqueScan(state, uniqueScan){
            state.qrStatsInformation.uniqueScan = uniqueScan;
        },
        SET_CITY(state, city) {
            state.city = city;
        },
        SET_COUNTRY(state, country) {
            state.country = country;
        }
    },
    actions: {
        getCurrentPlatform() {
            const platform = navigator.platform;
            if (platform.indexOf('Win') !== -1) return 'Windows';
            if (platform.indexOf('Mac') !== -1) return 'Mac OS';
            if (platform.indexOf('Linux') !== -1) return 'Linux';
            if (platform.indexOf('Android') !== -1) return 'Android';
            if (platform.indexOf('iPhone') !== -1) return 'iPhone';
            if (platform.indexOf('iPod') !== -1) return 'iPod';
            if (platform.indexOf('iPad') !== -1) return 'iPad';
            return 'Unknown';
        },
        async updateBranchQRStatsInDatabase({state}){
            let saveNewQRStatsInformationResp = saveNewQRStatsInformation(
                state.qrStatsInformation
            ).catch( error => {
                console.log(error)
            });
            if (document.cookie.indexOf("alreadyScanned=") === -1) {
                document.cookie = "alreadyScanned=true;path=/"; // Set the cookie with no expiry time
            }
        },
        async isUniqueScan( {commit}, uniqueScan){
            commit('setIsUniqueScan', (!uniqueScan ? 1 : 0))
        },
        async updateBranchQRStats( { commit, dispatch }, branchDetails){
            const location = await dispatch("getCurrentLocation");
            commit('setLocation', location);
            let platform = await dispatch("getCurrentPlatform");
            commit('setPlatform', platform);
            commit('setBranchDetails', branchDetails);
            await dispatch("updateBranchQRStatsInDatabase");
        },
        async getCurrentLocation({ commit, dispatch }) {
            if (navigator.geolocation) {
                try {
                    const { city, country_name } = await dispatch('getCityCountry');
                    commit('SET_CITY', city);
                    commit('SET_COUNTRY', country_name);
                    return {
                        'city': city,
                        'country_name': country_name
                    }
                } catch (error) {
                    console.log('Error:', error);
                    return null;
                }
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        },
        async getCityCountry() {
            try {
                const cityResp = await axios.get(`https://ipapi.co/city/`);
                const city = cityResp.data;
                const countryResp = await axios.get(`https://ipapi.co/country_name/`);
                const country_name =  countryResp.data
                return { city, country_name };
            } catch (error) {
                throw error;
            }
        },

    },
}

export default BranchQRStatsDetails;
<template xmlns="http://www.w3.org/1999/html">
    <section id="wrap-content" class="page-content">

        <div class="row">
            <div class="col-md-4">
                <label>Customer Name<span class="brown-color">*</span></label>
                <p><input type="text" name="name" required id="name" v-model="$store.getters['feedbackResponseDetails/getBranchFeedbackResponse'].customerName" class="reservation-fields" size="30" aria-required="true" aria-invalid="false"/></p>
            </div>
            <div class="col-md-4">
                <label>Customer Phone<span class="brown-color">*</span></label>
                <p><input type="tel" name="phoneNo" required id="phoneNo" v-model="$store.getters['feedbackResponseDetails/getBranchFeedbackResponse'].customerPhone" class="reservation-fields" size="30" aria-required="true" aria-invalid="false"/></p>
            </div>
            <div class="col-md-4">
                <label>Customer Email</label>
                <p><input type="email" name="email" id="email" v-model="$store.getters['feedbackResponseDetails/getBranchFeedbackResponse'].customerEmail" class="reservation-fields" size="30" aria-required="true" aria-invalid="false"/></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Customer Date Of Birth</label>
                <p><input type="date" name="DOB" id="DOB" min="1980-01-01" :max="new Date().toISOString().split('T')[0]" v-model="$store.getters['feedbackResponseDetails/getBranchFeedbackResponse'].customerDOB" class="reservation-fields" size="30" aria-required="true" aria-invalid="false"/></p>
            </div>
            <div class="col-md-4">
                <label>From where you heard about us?</label>
                <p>
                    <select name="platform" class="reservation-fields" aria-required="true" aria-invalid="false" v-model="$store.getters['feedbackResponseDetails/getBranchFeedbackResponse'].hearAboutUs">
                        <option value="Social Media">Social Media</option>
                        <option value="Friend">Friend</option>
                        <option value="Walk-in">Walk-in</option>
                    </select>
                </p>
            </div>
        </div>
    </section>

</template>

<script>

export default {
    name: "CustomerInformation",

}
</script>

<style scoped>

</style>
<template>
  <li>
    <h4 class="menu-title-section">
      <a class="" :href="'#cat_' + category.id" v-resize-text>{{ category.name }}</a>
    </h4>
    <div :id="'cat_' + category.id" class="menu-section">
      <div class="menu-holder menu-2col menu-accordion" >
        <template v-for="(item, index) in category.menuItemsById">
          <MenuItem :key="'menu_item_' + item.id" :item="item" :itemIndex=index />
        </template>
      </div>
    </div>
  </li>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  name: "category",
  components: {
    MenuItem,
  },
  props: ["category"]
};
</script>

<style scoped>
.menu-title-section:before {
  font-size: 16px;
}
</style>
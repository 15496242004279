<template>
    <section class="menu-2col-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <MenuNavigation />
                    <!-- FOOD MENU -->
                    <BranchFeedback/>
                    <!-- /FOOD MENU-->
                </div>
                <!--col-md-12-->
            </div>
            <!--row-->
        </div>
        <!--container-->
    </section>
</template>

<script>
import BranchFeedback from "@/components/FeedbackComponents/BranchFeedback.vue";

export default {
    name: "FeedbackView",
    components: {BranchFeedback},
}
</script>

<style scoped>

</style>
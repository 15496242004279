<template>
   <div class="menu-post clearfix bayked-menu-item" :class="{'last' : indexIfOdd}">
      <div class="menu-post-img">
        <div class="gallery" v-if="item.menuItemImagesByMenuItemId.length > 0">
          <template
            v-for="(image, index) in item.menuItemImagesByMenuItemId"
            
          >
            <a
              :key="index"
              :href="`${getOptimizedURL(image.imageUrl, 'gallery')}`"
              v-if="item.menuItemImagesByMenuItemId.length > 0"
              :title="item.name"
            >
              <img
                v-if="index === 0"
                :src="
                  item.menuItemImagesByMenuItemId.length > 0
                    ? getOptimizedURL(item.menuItemImagesByMenuItemId[0].imageUrl, 'display', 60, 60)
                    : '/assets/images/menu/double-tomato-bruschetta.jpg'
                "
                alt=""
              />
            </a>
          </template>
        </div>
        <div v-else class="gallery">
          <a
            href="/assets/images/menu/double-tomato-bruschetta.jpg"
            class="lightbox"
            title=""
          >
            <img
              width="400"
              height="400"
              src="/assets/images/menu/double-tomato-bruschetta.jpg"
              class="mr-2"
              alt=""
              title=""
            />
          </a>
        </div>
      </div>
      <div class="menu-post-desc">
        <h4>
          <span class="menu-title">{{ item.name }} </span>
          <span class="menu-dots"></span>
          <span class="menu-price"
            >Rs. {{ item.menuItemTypesById[0].price }}</span
          >
        </h4>
        <span class="menu-text serving-text" v-for="(menuItemType, index) in item.menuItemTypesById">
          {{ menuItemType.serving +",&nbspRs.&nbsp"+ menuItemType.price }}
          <span v-if="index+1 !== lengthOfServing">&nbsp|&nbsp</span>
          </span>
        <div class="menu-text">{{ item.description }}</div>
      </div>
    </div>
</template>

<script>
export default {
  name: "menuItem",
  props: ["item", "itemIndex"],
  computed: {
    lengthOfServing(){
      return this.item.menuItemTypesById.length
    },
    indexIfOdd () {
      return this.itemIndex % 2 != 0;
    }
  }
};
</script>

<template>
  <!-- MENU-->
  <Menu />
  <!-- /MENU-->
</template>

<script>
import Menu from "@/components/MenuComponents/Menu.vue";
export default {
  name: "MenuView",
  components: {
    Menu,
  },
};
</script>

<template>
  <section class="menu-2col-content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <MenuNavigation />
          <!-- FOOD MENU -->
          <ul class="our-menu">
            <template v-for="category in menu">
              <!-- STARTERS -->
              <Category
                :key="'cat_' + category.id"
                :category="category"
                v-if="category.menuItemsById.length > 0"
              />
            </template>
          </ul>
          <!-- /FOOD MENU-->
        </div>
        <!--col-md-12-->
      </div>
      <!--row-->
    </div>
    <!--container-->
  </section>
</template>

<script>
import Category from "./Category.vue";
export default {
  name: "Menu",
  components: { Category },
  computed: {
    menu() {
      return this.$store.getters["menuDetails/menuDetails"];
    },
  },
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import MenuView from '../views/MenuView.vue'
import GalleryView from '../views/GalleryView.vue';
import PageNotFound from "../views/PageNotFound.vue"
import FeedbackView from "@/views/FeedbackView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: `/:id`,
    name: 'menu',
    component: MenuView
  },
  {
    path: '/gallery/:id',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/feedback/:id',
    name: 'feedback',
    component: FeedbackView
  },
  {
    path: '/',
    name: 'PageNotFound',
    component: PageNotFound
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

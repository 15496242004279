<template>
  <section>
    <Loader v-if="isLoading" />
    <Navbar v-if="this.$route.params.id" />
    <Showcase v-if="isActiveQr"/>
    <router-view />
    <Footer v-if="this.$route.params.id" />
  </section>
</template>

<script>
import Navbar from "@/components/GlobalComponents/Navbar.vue";
import Showcase from "@/components/GlobalComponents/Showcase.vue";
import Footer from "@/components/GlobalComponents/Footer.vue";
import { getBranchQrActiveStatus } from "@/api/branchQrDetails"

export default {
  components: { Navbar, Footer, Showcase },
  data() {
    return {
      isLoading: true,
      isActiveQr: true,
    };
  },
  async mounted() {
    if (!this.$route.params.id) {
      return await this.redirectToPageNotFound()
    }
    const id = this.decryptRestaurantId(this.$route.params.id);
    if (!id) {
      return await this.redirectToPageNotFound()
    }
    if(await this.getBranchQRActiveStatus(id)){
      return false
    }
    await this.$store.dispatch("branchDetails/getBranchDetails", id);
    await this.$store.dispatch("menuDetails/getMenuDetails", id);
    await this.$store.dispatch("BranchQRStatsDetails/isUniqueScan", this.alreadySubmitted('alreadyScanned'))
    await this.$store.dispatch("BranchQRStatsDetails/updateBranchQRStats", this.$store.getters["branchDetails/branchDetails"]);
    if (!this.alreadySubmitted('alreadySubmitted')) {
        await this.$store.dispatch("feedbackDetails/getBranchFeedbackQuestions", id);
        await this.$store.dispatch("feedbackDetails/getBranchWaiters", id);
        await this.$store.dispatch("feedbackDetails/getBranchTables", id);
    }
    this.isLoading = false;
  },
  updated() {
    setTimeout(() => {
      this.loadScript();
    }, 300);
  },

  methods: {
    async getBranchQRActiveStatus(branchId) {
      if(!branchId) return false
      const {data} = await getBranchQrActiveStatus(branchId)
      if(!data.body) {
        return await this.redirectToPageNotFound()
      }
      return false
    },
    async redirectToPageNotFound() {
      this.isActiveQr = false
      this.isLoading = false;
      await this.$router.push({name: 'PageNotFound'});
      return true;
    }
  }
};
</script>



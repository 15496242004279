import { getBranchDetails } from '@/api/branchesApi.js'

const branchDetails = {
    namespaced: true,
    state: {
        branchDetails: {},
        socialMediaLinks: {
            facebook: '',
            instagram: '',
            youtube: '',
            twitter: ''
        }
    },
    getters: {
        branchDetails(state) {
            return state.branchDetails
        },
        socialMediaLinks(state) {
            return state.socialMediaLinks
        }
    },
    mutations: {
        setBranchDetails(state, branchDetails) {
            state.branchDetails = branchDetails
        },
        setSocialMediaLinks(state, data) {
            state.socialMediaLinks = data;
        }
    },
    actions: {
        /*API CALL TO GET ALL MENU ITEMS BY RESTAURANT ID */
        async getBranchDetails({ commit }, payload) {
            try {
                const { data } = await getBranchDetails(payload);
                commit('setBranchDetails', data);
                commit('setSocialMediaLinks', JSON.parse(data.socialMediaLinks));
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export default branchDetails;
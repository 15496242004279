<template xmlns="http://www.w3.org/1999/html">
    <section id="wrap-content" class="page-content">
        <div class="row">
            <div class="col-md-4">
                <label>Date and Time of Visit<span class="brown-color">*</span></label>
                <p><input type="datetime-local" name="datepicker" id="datepicker" :value="moment($store.getters['feedbackResponseDetails/getBranchFeedbackResponse'].dateAndTimeOfVisit).format('YYYY-MM-DDTHH:mm')" class="reservation-fields" size="30" aria-required="true" aria-invalid="false"/></p>
            </div>
            <div class="col-md-4">
                <label>Table Number<span class="brown-color">*</span></label>
                <p>
                    <select name="table-number" class="reservation-fields" aria-required="true" aria-invalid="false" @change="$store.dispatch('feedbackResponseDetails/updateTables', $event.target.value)">
                        <option v-for="(table, index) in tables" v-bind:value="table.id" v-bind:key="table.id+'_tablechoose'">
                            {{ table.name }}
                        </option>
                    </select>
                </p>
            </div>
            <div class="col-md-4">
                <label>Server Name<span class="brown-color">*</span></label>
                <p>
                    <select name="server-number" class="reservation-fields" aria-required="true" aria-invalid="false" @change="$store.dispatch('feedbackResponseDetails/updateWaiters', $event.target.value)">
                        <option v-for="server in waiters" v-bind:value="server.id" v-bind:key="server.id+'_serverchoose'">
                            {{ server.name }}
                        </option>
                    </select>
                </p>
            </div>
        </div>
    </section>
</template>


<script>

import moment from "moment/moment";

export default {
    name: "RestaurantInformation",
    computed: {
        moment() {
            return moment
        },
        waiters({commit}) {
            return this.$store.getters["feedbackDetails/waiters"];
        },
        tables({commit}){
            return this.$store.getters["feedbackDetails/tables"];
        }
    },
    watch: {
        tables: {
          handler(newTables) {
            if (newTables.length > 0) {
              this.$store.dispatch('feedbackResponseDetails/updateTables', newTables[0].id)
            }
          },
          deep: true,
          immediate: true,
        },
        waiters: {
          handler(newWaiters) {
            if (newWaiters.length > 0) {
              this.$store.dispatch('feedbackResponseDetails/updateWaiters', newWaiters[0].id)
            }
          },
          deep: true,
          immediate: true
        },
    }
}
</script>

<style>
.brown-color {
    color: #BC8D4B;
}
</style>